// const COLIGADA = 107;
const COLIGADA = 118;
const ANOSEMESTRE = "20211";
const TEL_WHATSAPP = "+5518996258825";
const TEL_WHATSAPP_ATENDIMENTO = "+5518996258825";
const TEL_WHATSAPP_POS = "+5518996258825";
const TEL_WHATSAPP_VENDAS = "+5518996258825";
const SOCIALS_NETWORK = {
  instagram: "https://www.instagram.com/faculdadealfamerica/",
  facebook: "https://www.facebook.com/alfamerica",
  youtube: "https://www.youtube.com/channel/UCg6MG7pbhQbriqaYh1Jc1dQ"
};

const CONTATO_TITLE = {
  ouvidoria: "Ouvidoria",
  "reclame-agora": "Reclame agora"
};
const CONTATO_ASSUNTOS = [
  "Dúvidas",
  "Sugestões",
  "Críticas",
  "Ouvidoria",
  "Outros"
];

const AREAS_CURSOS_POS = [
  {
    title: "Direito",
    slug: "direito",
    id: 15
  },
  {
    title: "Comunicação",
    slug: "comunicacao",
    id: 10
  },
  {
    title: "Gestão MBA",
    slug: "gestao-mba",
    id: 12
  },
  {
    title: "Educação",
    slug: "educacao",
    id: 11
  },
  {
    title: "Informática",
    slug: "informatica",
    id: 13
  },
  {
    title: "Saúde",
    slug: "saude",
    id: 14
  },
  {
    title: "Meio Ambiente",
    slug: "meio-ambiente",
    id: 16
  },
  {
    title: "Multidisciplinar",
    slug: "multidisciplinar",
    id: 9
  },
  {
    title: "Exatas",
    slug: "exatas",
    id: 1
  }
];

const MENSAGENS = {
  FORM_ENVIO: "Recebemos sua mensagem e em breve retornaremos seu contato.",
  NEWSLETTER:
    "Seu email foi cadastrado e você agora receberá recomendações especiais."
};

export {
  CONTATO_TITLE,
  CONTATO_ASSUNTOS,
  AREAS_CURSOS_POS,
  COLIGADA,
  TEL_WHATSAPP,
  TEL_WHATSAPP_VENDAS,
  TEL_WHATSAPP_ATENDIMENTO,
  TEL_WHATSAPP_POS,
  SOCIALS_NETWORK,
  ANOSEMESTRE,
  MENSAGENS
};
