import Axios from "axios";

/**
 * Configurações gerais das Apis
 */

Axios.defaults.headers.get["Content-Type"] = "application/json";
Axios.defaults.headers.get["Accept"] = "application/json";
// Axios.defaults.headers["X-Application-Token"] = "20ce20119370c78569c81dfcf1a15741";

// const FACULDADEPLAYAPI = Axios.create({
//   //Devlopment url
//   baseURL:
//   "http://localhost/faculdadeplayapi/"
//   //"https://faculdadeplay.edu.br/backend/faculdadeplayapi/"
//   //   process.env.NODE_ENV === "development"

// });

const FACULDADEPLAYAPI = Axios.create({
  //Devlopment url
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost/faculdadeplayapi/"
      : "https://faculdadealfamerica.edu.br/backend/faculdadeplayapi/"
});

const EMAIL = Axios.create({
  //Devlopment url
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8888/email-server/"
      : "https://faculdadealfamerica.edu.br/backend/email-server/"
  //baseURL: "https://faculdadealfamerica.edu.br/sitenovo/email-server/"
});


export {FACULDADEPLAYAPI, EMAIL};
