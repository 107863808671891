
import "@babel/polyfill"
import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.config.productionTip = false
import VueTheMask from "vue-the-mask";

Vue.config.productionTip = false

import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap/dist/js/bootstrap.js'

import '../src/assets/fonts/fonts.css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";
import "./css/fonts.css";
import "./css/icones.css";
import "./css/padroes.css";
import "./css/internas.css";


// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueTheMask);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
