<template>
  <div>
    <NavBar
      v-if="$route.path != '/brinquedoteca' && $route.path !== '/cpa' && $route.path !== '/cpa/noticias' && $route.path !== '/cpa/membros' && $route.path !== '/cpa/documentos' && $route.path !== '/cpa/legislacao' && $route.path !== '/cpa/relato-institucional' && $route.path !== '/cpa/painel-de-avaliacoes' && $route.path !== '/cpa/auto-avaliacao-acesso' && $route.path !== '/cpa/avaliacaocpa' && $route.path !== '/cpa/cadastrocpa' && $route.path !== '/cpa/tokencpa' && $route.path !== '/cpa/avaliacaoegresso' && $route.path !== '/cpa/avaliacaocolegiado' && $route.path !== '/cpa/avaliacaocoordenador'" />
    <router-view />
    <section class="d-flex justify-content-center p-0 banner-margin" v-if="$route.path === '/'">
      <div class="banner-top w-100" style="max-width: 1920px">
        <img class="logo-banner" src="../src/assets/img/logo-horizontal.svg" alt="Logo Faculdade Play">
      </div>
    </section>
    <main v-if="$route.path === '/'">
      <section id="documents">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="d-flex justify-content-around flex-wrap flex-lg-row w-100">
                <router-link to="/documentosInstitucionais" class="d-flex flex-column" id="rotalink">

                  <div class="container-type-documents mb-4 mb-md-0" style="background-color: #212B55;">
                    <div class="d-flex flex-column">
                      <div class="flex-auto">
                        <div class="container-type-documents-img">
                          <img src="../src/assets/img/icons/Documentos.png" style="width: 80px;"
                            alt="ícone de uma pasta de arquivos na cor branca" />
                        </div>
                      </div>
                      <div class="flex-shrink">
                        <div class="container-type-documents-text text-center">
                          <span>DOCUMENTOS</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>

                <div class="container-type-documents" style="background-color: #212B55;">
                  <a @click="openModal">
                    <div class="d-flex flex-column">
                      <div class="flex-auto">
                        <div class="container-type-documents-img">
                          <img src="../src/assets/img/icons/calendario.png" alt="ícone de um diploma na cor branca"
                            style="width: 80px;" />
                        </div>
                      </div>
                      <div class="flex-shrink">
                        <div class="container-type-documents-text text-center">
                          <span>CALENDÁRIO ACADÊMICO</span>
                        </div>
                      </div>
                    </div>
                  </a>
                  <div>

                    <b-modal v-model="modalOpen" @hide="closeModal" size="lg" centered hide-footer>
                      <!-- Conteúdo do modal -->
                      <!-- <h2>Informação do modal</h2> -->
                      <img :src="imagemUrl" alt="Imagem no modal" class="img-fluid">
                    </b-modal>
                  </div>
                </div>
                <router-link to="/validar" class="d-flex flex-column" id="rotalink">

                  <div class="container-type-documents mb-4 mb-md-0" style="background-color: #212B55;">
                    <div class="d-flex flex-column">
                      <div class="flex-auto">
                        <div class="container-type-documents-img">
                          <img src="../src/assets/img/icons/Validacao_Certificados.png" style="width: 80px;"
                            alt="ícone de um arquivo com um check ao lado validando na cor branca" />
                        </div>
                      </div>
                      <div class="flex-shrink">
                        <div class="container-type-documents-text text-center">
                          <span>VALIDAÇÃO DE CERTIFICADOS</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
                <a href="https://diplomas.somosb4.com.br" class="d-flex flex-column" id="rotalink">

                  <div class="container-type-documents" style="background-color: #212B55;">
                    <div class="d-flex flex-column">
                      <div class="flex-auto">
                        <div class="container-type-documents-img">
                          <img src="../src/assets/img/icons/Diploma_Digital.png" alt="ícone de um diploma na cor branca"
                            style="width: 80px;" />
                        </div>
                      </div>
                      <div class="flex-shrink">
                        <div class="container-type-documents-text text-center">
                          <span>DIPLOMA DIGITAL</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="beneficios">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <div class="container-title container-title-top d-flex align-items-center justify-content-center">
                  <h1 class="text-center m-0">A ALFAMÉRICA É A FACULDADE <br> QUE VOCÊ PROCURA</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-6 col-xl-4">
              <div class="container-benefits">
                <div class="d-flex flex-xl-row flex-column">
                  <div class="flex-auto">
                    <div class="container-benefits-img">
                      <img src="../src/assets/img/Ícone_Online.svg"
                        alt="ícone na cor azul representando um celular, tablet e monitor de computador" width="126">
                    </div>
                  </div>
                  <div class="flex-shrink">
                    <div class="container-benefits-text">
                      <span>ONLINE</span>
                      <p>Você estuda quando e onde quiser.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-xl-4">
              <div class="container-benefits">
                <div class="d-flex flex-xl-row flex-column">
                  <div class="flex-auto">
                    <div class="container-benefits-img">
                      <img src="../src/assets/img/Ícone_Acessível.svg"
                        alt="ícone na cor azul de uma mão segurando uma moeda" width="111">
                    </div>
                  </div>
                  <div class="flex-shrink">
                    <div class="container-benefits-text">
                      <span>ACESSÍVEL</span>
                      <p>Investimento que cabe no seu bolso.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="quemsomos" style="padding-top: 56px">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-xl-10">
              <div class="d-flex justify-content-center">
                <div class="container-title d-flex align-items-center justify-content-center">
                  <h2 class="text-center m-0">QUEM SOMOS</h2>
                </div>
              </div>
              <p class="p-decoration">
                A Alfamérica é uma <strong>instituição de ensino superior</strong>, fundada em 2005 na cidade de Praia
                Grande (SP).
                Trabalhamos para oferecer formação de extrema qualidade com foco no crescimento intelectual e
                criticidade.
              </p>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-12 col-md-6">
              <h3 class="mb-5">ATOS REGULATÓRIOS</h3>
              <h4>DIRETOR</h4>
              <p>Fábio de Sousa Ramos</p>
              <div class="mt-5 mb-5">
                <h4>ATO REGULATÓRIO INSTITUCIONAL</h4>
                <p>Credenciamento pela Portaria N° 569/2005 D.O.U. de 01/03/2005.</p>

                <p>Recredenciamento pela Portaria N° 317/2019 D.O.U. de 11/02/2019.</p>

                <p>Credenciamento EAD pela Portaria N° 1109/2017 D.O.U. 14/09/2017.</p>
              </div>

              <!-- <div>
              <h4>ATO REGULATÓRIO DE CURSO</h4>
              <h5>Bacharelado em Ciências Contábeis a distância.</h5>
              <p>Autorização de Curso pela Portaria Nº 344/2019 D.O.U de 15/07/2019.</p>

              <h5>Licenciatura em Sociologia a distância.</h5>
              <p>Autorização de Curso pela Portaria Nº 388/2019 D.O.U de 12/07/2019.</p>
            </div> -->
            </div>
            <div class="col-12 col-md-6">
              <h5>Licenciatura em Pedagogia a distância.</h5>
              <p>Autorização de Curso pela Portaria Nº 1207/2017 D.O.U de 27/11/2017.</p>

              <h5>Bacharelado em Administração a distância.</h5>
              <p>Autorização de Curso pela Portaria Nº 1207/2017 D.O.U de 27/11/2017.</p>

              <!-- <h5>Licenciatura em Pedagogia - Presencial.</h5>
            <p>Autorização de Curso pela Portaria Nº 506/2006 D.O.U de 18/08/2006.</p>
            <p>Reconhecimento de Curso pela Portaria Nº 530/2009 D.O.U de 15/04/2009.</p>
            <p>Renovação de Reconhecimento de Curso pela Portaria Nº 286/2012 – D.O.U de 27/12/2012.</p>
            <p>Reconhecimento de Curso pela Portaria Nº 185/2018 D.O.U de 19/03/2018</p>
            <p>Renovação de Reconhecimento de Curso pela Portaria Nº 85/2019 – D.O.U de 21/02/2019</p> -->
              <img class="w-100 quemsomos-mob" src="../src/assets/img/QuemSomos_Mobile.png"
                alt="Homem negro mexendo em um notebook sob a mesa. Ele usa óculos arredondados e está sorrindo para a tela. Veste camisa jeans preta e camiseta branca por baixo">
            </div>
          </div>
        </div>
      </section>
      <section id="graduacao">
        <div class="container">
          <div class="row justify-content-center mb-5">
            <div class="col-12 col-xl-10">
              <div class="d-flex justify-content-center">
                <div class="container-title d-flex align-items-center justify-content-center">
                  <h2 class="text-center m-0">GRADUAÇÃO</h2>
                </div>
              </div>
              <p class="text-light p-decoration">
                Os cursos de graduação EAD da Alfamérica são <b>desenvolvidos por especialistas</b> que trazem todo o
                conteúdo necessário para uma formação multidisciplinar e atualizada,
                a fim de preparar nossos alunos para o mercado de trabalho.
              </p>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-xl-10">
              <div id="carouselExampleControls" class="carousel slide d-none d-xl-block" data-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">

                    <div class="carousel-item-container d-flex">
                      <div class="row">
                        <div class="col-4 d-flex justify-content-center">
                          <div class="carousel-item-container-img">
                            <img src="./assets/img/slider/Pedagogia_Desktop.png"
                              alt="Mulher negra sorrindo e de braços cruzados. Veste uma camiseta de bolso azul e uma calça branca."
                              width="260" height="438">
                          </div>
                        </div>
                        <div class="col-8">
                          <div class="carousel-item-container-text">
                            <div class="carousel-item-container-text-titleCourse d-flex align-items-center">
                              <h3>Pedagogia</h3>
                              <div class="course-time">
                                <span>Duração: 4 anos</span>
                              </div>
                            </div>
                            <h4>SOBRE O CURSO</h4>
                            <p>A formação em pedagogia é baseada nas ciências humanas e sociais. O curso abrange as
                              diferentes metodologias e fases da alfabetização, processos de ensino e aprendizagem,
                              entre outros temas que envolvem a educação.</p>
                            <h4>MERCADO DE TRABALHO</h4>
                            <p>O pedagogo pode atuar na educação pública ou privada como docente ou coordenador. Também
                              é possível extrapolar o ambiente escolar, atuando em empresas e hospitais com projetos
                              educacionais.</p>
                            <h4>BASE SALARIAL</h4>
                            <p>O salário de um pedagogo é, em média, de R$ 3.600,00. Ao ocupar um cargo de gestão, a
                              remuneração pode ultrapassar os R$ 6.000,00.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="carousel-item-container d-flex">
                      <div class="row">
                        <div class="col-4">
                          <div class="carousel-item-container-img">
                            <img src="./assets/img/slider/Administração_Desktop.png"
                              alt="Homem branco de pele escura sorrindo e com os braços cruzados. Veste uma camisa social bege de mangas longas e calça cinza xadrez."
                              width="260" height="438">
                          </div>
                        </div>
                        <div class="col-8">
                          <div class="carousel-item-container-text">
                            <div class="carousel-item-container-text-titleCourse d-flex align-items-center">
                              <h3>Administração</h3>
                              <div class="course-time">
                                <span>Duração: 4 anos</span>
                              </div>
                            </div>
                            <h4>SOBRE O CURSO</h4>
                            <p>Cursar Administração traz as ferramentas necessárias para atuar na organização e
                              gerenciamento de empresas, através dos conhecimentos adquiridos sobre contabilidade,
                              economia, finanças, planejamento, marketing, entre outros.</p>
                            <h4>MERCADO DE TRABALHO</h4>
                            <p>O profissional da área pode atuar em empresas públicas ou privadas dos mais variados
                              segmentos e portes, inclusive mercado financeiro ou administrando seu próprio negócio.</p>
                            <h4>BASE SALARIAL</h4>
                            <p>O salário de um administrador varia de acordo com o porte da empresa contratante. Em
                              geral, um analista pode chegar a ganhar R$ 3.700,00, enquanto que o diretor de uma grande
                              empresa pode ultrapassar o salário de R$ 25.000,00.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-target="#carouselExampleControls"
                  data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-target="#carouselExampleControls"
                  data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </button>
              </div>
              <div class="d-xl-none carousel-item-container">
                <div class="row flex-column">
                  <div class="col-12 d-flex justify-content-center">
                    <div class="carousel-item-container-img">
                      <img src="./assets/img/slider/Pedagogia_Mobile.png"
                        alt="Mulher negra sorrindo e de braços cruzados. Veste uma camiseta de bolso azul e uma calça branca.">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="carousel-item-container-text">
                      <div class="carousel-item-container-text-titleCourse d-flex align-items-center">
                        <h3>Pedagogia</h3>
                        <div class="course-time">
                          <span>Duração: 4 anos</span>
                        </div>
                      </div>
                      <h4>SOBRE O CURSO</h4>
                      <p>A formação em pedagogia é baseada nas ciências humanas e sociais. O curso abrange as diferentes
                        metodologias e fases da alfabetização, processos de ensino e aprendizagem, entre outros temas
                        que envolvem a educação.</p>
                      <h4>MERCADO DE TRABALHO</h4>
                      <p>O pedagogo pode atuar na educação pública ou privada como docente ou coordenador. Também é
                        possível extrapolar o ambiente escolar, atuando em empresas e hospitais com projetos
                        educacionais.</p>
                      <h4>BASE SALARIAL</h4>
                      <p>O salário de um pedagogo é, em média, de R$ 3.600,00. Ao ocupar um cargo de gestão, a
                        remuneração pode ultrapassar os R$ 6.000,00.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-xl-none carousel-item-container d-flex">
                <div class="row">
                  <div class="col-12">
                    <div class="carousel-item-container-img">
                      <img src="./assets/img/slider/Administração_Mobile.png"
                        alt="Homem branco de pele escura sorrindo e com os braços cruzados. Veste uma camisa social bege de mangas longas e calça cinza xadrez.">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="carousel-item-container-text">
                      <div class="carousel-item-container-text-titleCourse d-flex align-items-center">
                        <h3>Administração</h3>
                        <div class="course-time">
                          <span>Duração: 4 anos</span>
                        </div>
                      </div>
                      <h4>SOBRE O CURSO</h4>
                      <p>Cursar Administração traz as ferramentas necessárias para atuar na organização e gerenciamento
                        de empresas, através dos conhecimentos adquiridos sobre contabilidade, economia, finanças,
                        planejamento, marketing, entre outros.</p>
                      <h4>MERCADO DE TRABALHO</h4>
                      <p>O profissional da área pode atuar em empresas públicas ou privadas dos mais variados segmentos
                        e portes, inclusive mercado financeiro ou administrando seu próprio negócio.</p>
                      <h4>BASE SALARIAL</h4>
                      <p>O salário de um administrador varia de acordo com o porte da empresa contratante. Em geral, um
                        analista pode chegar a ganhar R$ 3.700,00, enquanto que o diretor de uma grande empresa pode
                        ultrapassar o salário de R$ 25.000,00.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="comunicados" style="padding: 56px 0;">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-xl-10">
              <div class="d-flex justify-content-center">
                <div class="container-title d-flex align-items-center justify-content-center">
                  <h2 class="text-center m-0">COMUNICADOS</h2>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div id="carouselExampleControls2" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item item2 active">
                    <div class="row justify-content-center">
                      <div class="col-12 col-lg-8 d-flex justify-content-center">
                        <img src="./assets/img/comunicados/comunicado-autoavaliacao.jpg" alt="">
                      </div>
                    </div>
                  </div>

                  <div class="carousel-item item2">
                    <div class="row justify-content-center">
                      <div class="col-12 col-lg-8 d-flex justify-content-center">
                        <img src="./assets/img/comunicados/comunicado-avaliacao-da-cpa.jpg" alt="">
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item item2">
                    <div class="row justify-content-center">
                      <div class="col-12 col-lg-8 d-flex justify-content-center">
                        <img src="./assets/img/comunicados/comunicado-avaliacao-institucional.jpg" alt="">
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item item2">
                    <div class="row justify-content-center">
                      <div class="col-12 col-lg-8 d-flex justify-content-center">
                        <img src="./assets/img/comunicados/recredenciamento2024.jpg" alt="">
                      </div>
                    </div>
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-target="#carouselExampleControls2"
                  data-slide="prev">
                  <span class="carousel-control-prev-icon control2" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </button>
                <button class="carousel-control-next " type="button" data-target="#carouselExampleControls2"
                  data-slide="next">
                  <span class="carousel-control-next-icon control2" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12 d-flex justify-content-center">
            <router-link to="/comunicados" class="w-100 d-flex justify-content-center text-decoration-none">
              <button class="seeAnnouncements">
                <span>VER TODOS</span>
              </button>
            </router-link>
          </div>
        </div>
      </div>

      <FormContact />
      <button class="scroll-top-button" v-show="showScrollButton" @click="scrollToTop()">
        <img src="../src/assets/img/icons/arrow-up.svg" alt="seta branca com a ponta para cima">
        <span style="font-size: 14px; line-height: 0.8; margin-top: 5px">Voltar <br> ao topo</span>
      </button>
    </main>
    <FooterBar
      v-if="$route.path !== '/cpa' && $route.path !== '/cpa/noticias' && $route.path !== '/cpa/membros' && $route.path !== '/cpa/documentos' && $route.path !== '/cpa/legislacao' && $route.path !== '/cpa/relato-institucional' && $route.path !== '/cpa/painel-de-avaliacoes' && $route.path !== '/cpa/auto-avaliacao-acesso' && $route.path !== '/cpa/avaliacaocpa' && $route.path !== '/cpa/cadastrocpa' && $route.path !== '/cpa/tokencpa' && $route.path !== '/cpa/avaliacaoegresso' && $route.path !== '/cpa/avaliacaocolegiado' && $route.path !== '/cpa/avaliacaocoordenador'" />
    <div vw class="enabled">
      <div vw-access-button class="active"></div>
      <div vw-plugin-wrapper>
        <div class="vw-plugin-top-wrapper"></div>
      </div>
    </div>
  </div>
</template>

<script>

import NavBar from './components/NavBar.vue'
import FooterBar from './components/Footer.vue'
import FormContact from './components/FormContact.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    FooterBar,
    FormContact
  },
  data() {
    return {
      showScrollButton: false,
      scrollOffset: 800,
      modalOpen: false,
      imagemUrl: require('../src/assets/img/calendario24.jpeg') // Caminho da imagem
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    showButton() {
      this.showScrollButton = window.pageYOffset > this.scrollOffset ? true : false;
      // console.log(window.pageYOffset);
    },
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.showButton);
  },

  destroyed() {
    window.removeEventListener('scroll', this.showButton);
  },
}
</script>

<style>
@media (min-width: 576px) {

  .banner-margin,
  .paginas-internas,
  .bannerTop {
    margin-top: 81px;
  }
}

@media (max-width: 575px) {

  .banner-margin,
  .paginas-internas,
  .bannerTop {
    margin-top: 40px;
  }
}

.item2 img {
  max-width: 100%;
  /* Garante que a imagem nunca exceda a largura do contêiner */
  height: 500px;
  /* Altura fixa para todas as imagens */
  object-fit: cover;
  /* Garante que a imagem cubra o espaço disponível sem distorcer */
}

.seeAnnouncements {
  width: 100%;
  max-width: 200px;
  background-color: unset;
  border: 2px solid #B7182E;
  border-radius: 28px;
  font-size: 23px;
  color: #B7182E;
}

.scroll-top-button {
  position: fixed;
  z-index: 99;
  bottom: 150px;
  right: 20px;
  padding: 10px;
  background-color: #B7182E;
  color: #FFFFFF;
  border: 2px solid #FFFFFF;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

ul {
  list-style: none;
  margin: 0 !important;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

section {
  padding-top: 100px !important;
}

.flex-auto {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
}

.flex-shrink {
  flex: 0 1 auto;
}


.banner-top {
  background: url('../src/assets/img/banner-fundo.jpg') no-repeat center;
  height: 690px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.logo-banner {
  width: 100%;
  max-width: 700px;
  margin-left: 90px;
}


@media (min-width: 1025px) and (max-width: 1380px) {
  .logo-banner {
    max-width: 580px;
    margin-left: 40px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .banner-top {
    background-position: 60%;
  }

  .logo-banner {
    max-width: 350px;
  }
}

@media (max-width: 767px) {
  .banner-top {
    background: url('../src/assets/img/banner-fundo-mobile.jpg') no-repeat center;
    height: 400px;
    align-items: flex-start;
    justify-content: center;
    padding-top: 24px;
  }

  .logo-banner {
    max-width: 200px;
    margin-left: unset;
  }
}

h3 {
  font-size: 38px;
  color: #B7182E;
}


#quemsomos h3,
h4 {
  font-family: 'Effra Heavy', sans-serif;
}

h5 {
  font-family: 'Effra Bold', sans-serif;
}

h4 {
  font-size: 27px;
  color: #B7182E;
}

h5 {
  font-size: 20px;
  color: #212B55;
  text-decoration: underline;
}

#beneficios h1,
#graduacao h2,
#quemsomos h2,
#contato h2,
#comunicados {
  font-family: 'Effra Bold', sans-serif;
  font-size: 32px;
  color: #FFFFFF;
}

@media (max-width: 767px) {
  .p-decoration {
    font-size: 16px;
    padding-left: 10px;
  }

  .p-decoration::before {
    left: 10px;
  }

  #beneficios h1 {
    font-size: 20px;
  }

  #graduacao h2,
  #quemsomos h2,
  #contato h2,
  #comunicados h2 {
    font-size: 18px !important;
  }

  #quemsomos p {
    font-family: 'Effra Medium', sans-serif;
  }

  #quemsomos h3 {
    font-size: 18px;
    margin-bottom: 20px !important;
  }

  #quemsomos h4 {
    font-size: 16px;
  }
}

#graduacao {
  background-color: #212B55;
  padding: 56px 0;
}


@media (min-width: 768px) and (max-width: 1199px) {
  .quemsomos-mob {
    margin-top: -120px;
  }
}

.p-decoration {
  font-size: 24px;
  font-family: 'Effra', sans-serif;
}

.p-decoration::before {
  content: '';
  width: 8px;
  height: 110px;
  background-color: #B7182E;
  position: absolute;
  left: -18px;
}

#contato h2 {
  font-family: 'Effra Bold', sans-serif;
  font-size: 32px;
  color: #212B55;
}

.container-title {
  background-color: #B7182E;
  width: 100%;
  max-width: 350px;
  height: 48px;
  position: relative;
  margin-bottom: 70px;
}

.container-title.container-title-top {
  max-width: 730px;
  height: 120px;
}

.container-title.container-title-top::before,
.container-title.container-title-top::after {
  height: 120px;
}

.container-title::before,
.container-title::after {
  content: '';
  width: 8px;
  height: 48px;
  background-color: #B7182E;
  position: absolute;
  left: -18px;
}

.container-title::after {
  left: unset;
  right: -18px;
}


@media (max-width: 767px) {
  .container-title {
    max-width: 300px;
    height: 40px;
  }

  .container-title::before,
  .container-title::after {
    width: 2px;
    height: 40px;
    left: -4px;
  }

  .container-title::after {
    left: unset;
    right: -4px;
  }

  .container-title.container-title-top {
    max-width: 300px;
    height: 52px;
  }

  .container-title.container-title-top::before,
  .container-title.container-title-top::after {
    height: 52px;
  }
}

#beneficios {
  background-color: #212B55;
  padding: 80px 0;
}

.container-benefits {
  background-color: #FFFFFF;
  border-radius: 20px;
  width: 100%;
  max-width: 340px;
  height: 100%;
  min-height: 174px;
  padding: 30px 28px;
  display: flex;
  justify-content: center;
}

.container-benefits-text {
  font-size: 22px;
  margin-left: 30px;
}

.container-benefits-text span {
  font-family: 'Effra Heavy', sans-serif;
  color: #B7182E;
}

.container-benefits-text p {
  font-family: 'Effra Medium', sans-serif;
  color: #212B55;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  #beneficios {
    padding: 46px 0;
  }

  .container-benefits-img {
    display: flex;
  }

  .container-benefits-img img {
    max-width: 56px !important;
  }

  .container-benefits-text {
    font-size: 16px;
  }

  .container-benefits {
    padding: 15px;
  }
}

@media (max-width: 1199px) {
  .container-benefits-text {
    margin-left: unset;
    text-align: center;
  }
}


#documents {
  background-color: #ffff;
  padding-bottom: 56px;
}

#rotalink {
  text-decoration: none;
}

.flex-auto {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
}

.flex-shrink {
  flex: 0 1 auto;
}

.container-type-documents {
  background-color: #FFFFFF;
  border-radius: 25px;
  width: 100%;
  max-width: 224px;
  min-height: 212px;
  padding: 30px 28px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.container-type-documents-text span {
  font-family: "Effra Heavy", sans-serif;
  font-size: 25px;
  color: #ffff;
  line-height: 1;
}

.carousel-control-prev,
.carousel-control-next {
  opacity: 1 !important;
}

@media (max-width: 767px) {
  .container-type-documents {
    max-width: 145px;
    height: 140px;
    min-height: unset;
  }

  .container-type-documents-img img {
    max-width: 56px !important;
  }

  .container-type-documents-text span {
    font-size: 16px;
  }

  .container-type-documents {
    padding: 15px;
  }
}

@media (max-width: 1199px) {
  .container-type-documents-text {
    margin-left: unset;
    text-align: center;
  }
}

/* .carousel-item {
    display: flex;
    justify-content: center;
  } */
.carousel-item-container {
  background-color: #FFFFFF;
  border-radius: 30px;
  padding: 26px 62px 44px;
  max-width: 920px;
}

.carousel-control-next,
.carousel-control-prev {
  background-color: unset;
  border: unset;
}

.carousel-item-container-text h4 {
  font-family: 'Effra Bold', sans-serif;
  text-decoration: underline;
}

.carousel-item-container-text p {
  font-family: 'Effra Medium', sans-serif;
  font-size: 16px;
  color: #212B55;
  line-height: 1.2;
}

/* .carousel-item-container-text-titleCourse {
    margin-bottom: 36px;
  } */
.carousel-item-container-text-titleCourse>h3 {
  font-family: 'Effra Bold', sans-serif;
  font-size: 36px;
}

.course-time {
  background-color: #B7182E;
  width: 100%;
  max-width: 150px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.course-time>span {
  font-family: 'Effra Bold', sans-serif;
  font-size: 18px;
  color: #FFFFFF;
}

@media (min-width: 1200px) {
  #carouselExampleControls {
    position: relative;
  }

  .carousel-control-next {
    position: absolute !important;
    right: -70px !important;
    display: flex !important;
    justify-content: flex-end !important;
  }

  .carousel-control-prev {
    position: absolute !important;
    left: -70px !important;
    display: flex !important;
    justify-content: flex-start !important;
  }

  #carouselExampleControls .carousel-control-prev-icon {
    background: url('../src/assets/img/arrow-prev.svg') !important;
  }

  #carouselExampleControls .carousel-control-next-icon {
    background: url('../src/assets/img/arrow-next.svg') !important;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 42px !important;
    height: 67px !important;

  }

  .control2 {
    width: 42px !important;
    height: 57px !important;
  }
}

@media (max-width: 767px) {
  .carousel-item-container {
    padding: 26px 20px 44px;
  }

  .carousel-item-container-text-titleCourse>h3 {
    font-size: 22px;
  }

  .carousel-item-container-text h4 {
    font-size: 18px;
  }

  .course-time>span {
    font-size: 16px;
  }
}

@media (min-width: 991px) {
  #carouselExampleControls2 .carousel-control-prev-icon {
    background-image: url('../src/assets/img/arrow-prev-blue.svg') !important;
  }

  #carouselExampleControls2 .carousel-control-next-icon {
    background-image: url('../src/assets/img/arrow-next-blue.svg') !important;
  }
}

@media (max-width: 1199px) {
  .carousel-item-container {
    margin-top: 160px;
  }

  .carousel-item-container-text {
    margin-top: 20px;
  }

  .carousel-item-container-text-titleCourse {
    margin-bottom: 10px;
  }

  .carousel-item-container-img {
    margin-top: -160px;
  }

  .carousel-item-container-img img {
    max-width: 100%;
  }
}
</style>
