<template>
  <section id="contato">
    <div class="container">
      <div class="row justify-content-center mb-4">
        <div class="col-12 col-xl-10">
          <div class="d-flex justify-content-center">
            <div class="container-title d-flex align-items-center justify-content-center mb-4">
              <h2  style="color: #FFFFFF;" class="text-center m-0">CONTATO</h2>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-10 text-center">
          <p style="font-family: 'Effra', sans-serif; font-size: 25px; color: #212B55;">Deixe sua mensagem que entraremos em contato.</p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-xl-10">
            <b-form class="form-contact" @submit="onSubmit">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <b-form-input
                  id="name"
                  v-model="form.nome"
                  placeholder="Seu nome completo"
                  required
                ></b-form-input>
                </div>
                <div class="form-group col-md-6">
                  <b-form-input
                  id="telefone"
                  v-model="form.telefone"
                  v-mask="['(##) ####-####', '(##) #####-####']"
                  type="tel"
                  required
                  placeholder="Seu telefone"
                ></b-form-input>
                </div>
              </div>
              <div class="form-group">
                <b-form-input
                  id="email"
                  v-model="form.email"
                  type="email"
                  required
                  placeholder="Seu email"
                ></b-form-input>
              </div>
              <div class="form-group">
                <b-form-input
                  id="assunto"
                  v-model="form.assunto"
                  placeholder="Assunto"
                  required
                ></b-form-input>
              </div>
              <b-form-textarea
                  rows="10"
                  v-model="form.mensagem"
                  id="message"
                  placeholder="Conte-nos a sua necessidade"
                  />
                <div id="containerRacapBut" :class="{mx_form_invalido: formInvalido}">
                    <div id="recapContato" :class="{mx_recaptcha_failed: !recaptcha}">
                      <template>
                        <vue-recaptcha id="containerRecaptcha" @verify="mxVerify" sitekey="6Lc-2NsmAAAAABptxAhhBKO6kvh2f_qVUJOAZLWD"></vue-recaptcha>
                        <!-- <vue-recaptcha id="containerRecaptcha" @verify="mxVerify" sitekey="6Lc_68cbAAAAAP2wvYWN1JDWk0CrQiP_O6zHn_9z"></vue-recaptcha> -->
                        
                      </template>
                      <span class="avisoCaptcha">Complete o Captcha!</span>
                    </div>
                    <div class="d-flex justify-content-center mt-5" style="width: 100%;">
                      <button type="submit" class="btn btn-send">ENVIAR</button>
                    </div>
                  </div>
                  <b-overlay :show="loading" rounded="sm" no-wrap />
                </b-form>
            </div>
      </div>
    </div>
  </section>
</template>

<script>

import { MENSAGENS } from "@/constants/Constants";
import ContatoService from "@/services/ContatoService";
import {VueRecaptcha} from 'vue-recaptcha';

const INITIAL_FORM = {
  tipo: "Contato",
  nome: "",
  telefone: "",
  email: "",
  assunto: "",
  mensagem: "",
  typeEmail: "contato"
};

export default {
  name: 'FormContact',
  components: {VueRecaptcha },
  data(){
    return{
      formInvalido : false,
      recaptcha    : null,
      form: {
        ...INITIAL_FORM
      },
      loading: false,
      // subjects: [
      //   ...CONTATO_ASSUNTOS
      // ]
    }
  },
  mounted() {
    // this.setSelectValue();

    let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit')
        document.head.appendChild(recaptchaScript)
  },
  methods: {
    mxVerify( response ) {
      this.recaptcha = response;
    },
    // setSelectValue() {
    //   this.form.assunto = CONTATO_ASSUNTOS.includes(this.selectValue)
    //     ? this.selectValue
    //     : null;
    // },
    async onSubmit(evt) {
      evt.preventDefault();
      try {

        // validacao recaptcha
        if(!this.recaptcha){
          this.formInvalido = true;
          return false;
        }

        this.loading = true;
        let response = await ContatoService.sendEmail(this.form);
        this.loading = false;
        this.form = INITIAL_FORM;
        if (response?.data?.success) {
          this.$bvModal.msgBoxOk(MENSAGENS.FORM_ENVIO, {
            title: "Confirmação",
            size: "sm",
            buttonSize: "sm",
            okTitle: "Continuar",
            okVariant: "success",
            headerClass: "p-2 cor-secundaria",
            footerClass: "p-2",
            centered: true
          });
          // reseta recaptcha
          this.recaptcha.reset();
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>

<style>

.avisoCaptcha{
  display: none;
}
.contact{
  color: #FFFFFF;
}
.mx_form_invalido .mx_recaptcha_failed span{
  display: flex;
  justify-content: center;
  color: red;
  font-weight: bold;
}
#g-recaptcha-response {
  display: block !important;
  position: absolute;
  margin: -78px 0 0 0 !important;
  width: 302px !important;
  height: 76px !important;
  z-index: -999999;
  opacity: 0;
}

#containerRacapBut{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

section#contato {
  background-color: #212B55;
  padding-bottom: 56px;
}

input.form-control,
textarea {
  border: 2px solid #B7182E !important;
  border-radius: 6px !important;
}

textarea {
  padding: 0.375rem 0.75rem;
}

.btn.btn-send {
  font-family: 'Effra Heavy', sans-serif;
  font-size: 25px;
  color: #212b55;
  background-color: #FFFFFF;
  width: 100%;
  max-width: 255.44px;
  height: 45px;
  border-radius: 20px;
  padding: unset;
}

.btn.btn-send:hover {
  color: #B7182E;
}</style>