<template>
  <div>
   
  </div>
</template>

<script>
export default {
  name: 'HomeMain'
}
</script>

<style scoped>
.banner-top {
  background: url('../assets/img/banner-fundo.jpg') no-repeat #2A5BA7;
  height: 671px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
}

.logo-banner {
  max-width: 620px;
}

@media (max-width: 1060px) {
  .banner-top {
    background: url('../assets/img/banner-fundo-mobile.jpg') no-repeat #2A5BA7;
    background-position: center;
    height: 373px;
  }

  .logo-banner {
    display: none;
  }
}

@media (min-width: 1061px) {
  .logo-banner {
    display: block;
  }
}

@media (min-width: 1061px) and (max-width: 1200px) {
  .logo-banner {
    max-width: 400px;
  }
}

@media (min-width: 1200px) and (max-width: 1365px) {
  .logo-banner {
    max-width: 520px;
  }
}

main {
  padding: 50px 0;
  display: flex !important;
  align-items: center;
}

.flex-auto {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
}

.flex-shrink {
  flex: 0 1 auto;
}

.container-type-documents {
  background-color: #00ACEC;
  border-radius: 25px;
  width: 100%;
  max-width: 224px;
  min-height: 212px;
  padding: 30px 28px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.container-type-documents-text span {
  font-family: "Effra Heavy", sans-serif;
  font-size: 25px;
  color: #FFFFFF;
  line-height: 1;
}

@media (max-width: 767px) {
  .container-type-documents {
    max-width: 145px;
    height: 140px;
    min-height: unset;
  }

  .container-type-documents-img img {
    max-width: 56px !important;
  }

  .container-type-documents-text span {
    font-size: 16px;
  }

  .container-type-documents {
    padding: 15px;
  }
}

@media (max-width: 1199px) {
  .container-type-documents-text {
    margin-left: unset;
    text-align: center;
  }
}
</style>